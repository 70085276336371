<template>
  <div class="step-container advisor">
    <v-form name="ClientAccountSelection" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select all <strong>accounts</strong>
                <span v-if="!missingClients">
                  for <strong>{{ clientNames }}</strong>
                </span>
              </span>
            </h1>
          </v-col>
        </v-row>
        <!-- No Clients -->
        <div class="alert" v-if="missingClients">
          <div class="pr-2">
            <span class="fa-icon left">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <div>
            {{ missingClientsText }}
          </div>
        </div>
        <v-row class="justify-center full" v-if="!missingClients">
          <v-col class="col-12 col-sm-4">
            <div>
              <div class="faux-label required">Select an Account Type</div>
              <div>
                <v-select
                  v-model="addGroup.accountType"
                  :items="accountTypes"
                  :backgroundColor="inputBackgroundColor"
                  ref="accountList"
                  outlined
                  aria-required="true"
                  placeholder="Choose Account Type"
                  dense
                  :readonly="editing"
                  hide-details="auto"
                  @change="$v.addGroup.accountType.$touch()"
                  :error-messages="accountTypeErrors"
                >
                </v-select>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="addGroup.accountType"
            class="col-12 col-sm-4 align-self-end"
          >
            <label for="nickname" class="required">Nickname</label>
            <v-text-field
              outlined
              type="text"
              maxlength="50"
              name="nickname"
              aria-required="true"
              v-model.trim="addGroup.nickname"
              :backgroundColor="inputBackgroundColor"
              @input="$v.addGroup.nickname.$touch()"
              @blur="$v.addGroup.nickname.$touch()"
              :error-messages="nicknameErrors"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row
          class="justify-center full"
          v-if="!missingClients && addGroup.accountType"
        >
          <v-col
            class="col-12 col-sm-4"
            v-show="addGroup.accountType !== ACCOUNT_TYPES.JOINT_NAME_BROKERAGE"
          >
            <div>
              <div class="faux-label required">
                Who do you want to open it for? Select all that apply.
              </div>
              <div>
                <v-select
                  v-model="addGroup.accountClients"
                  ref="clientList"
                  :items="clients"
                  :backgroundColor="inputBackgroundColor"
                  chips
                  outlined
                  placeholder="Choose"
                  aria-required="true"
                  multiple
                  dense
                  hide-details="auto"
                  @change="$v.addGroup.accountClients.$touch()"
                  :error-messages="accountClientErrors"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="prepend">
                          Choose
                          <a class="close" @click="$refs.clientList.blur()">
                            <div><i class="fas fa-times"></i></div>
                          </a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </div>
            </div>
          </v-col>
          <v-col
            class="col-12 col-sm-4"
            v-show="addGroup.accountType === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE"
          >
            <div>
              <div class="faux-label">Type</div>
              <div>
                <v-select
                  v-model="addGroup.jnbType"
                  :items="jnbTypes"
                  :backgroundColor="inputBackgroundColor"
                  outlined
                  placeholder="Choose"
                  dense
                  hide-details="auto"
                  @change="$v.addGroup.jnbType.$touch()"
                  :error-messages="jnbTypeErrors"
                >
                </v-select>
              </div>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-4">
            <a
              id="save"
              @click="saveAccount(editIndex)"
              class="btn save-btn"
              v-if="editing"
            >
              Save Account
            </a>
            <a v-else id="add" @click="saveAccount()" class="btn add-btn">
              <span>Add Account</span>
              <span class="fa-icon">
                <i class="fas fa-plus"></i>
              </span>
            </a>
            <a id="cancel" @click="clearAccount" class="cancel-btn"> Cancel </a>
          </v-col>
        </v-row>

        <!-- Account List Table -->
        <v-row class="full">
          <v-col class="col-12 full">
            <v-data-table
              :headers="headers"
              :items="accounts"
              hide-default-footer
              disable-pagination
              :hide-default-header="isMobile"
              class="responsive-table account-table"
              :class="{ mobile: isMobile }"
              ref="accountTable"
              v-resize="onResize"
            >
              <template slot="item" slot-scope="props" v-if="!tableLoading">
                <tr v-if="!isMobile" :key="props.item.label">
                  <td width="25%">{{ props.item.type }}</td>
                  <td width="20%">{{ props.item.label }}</td>
                  <td width="40%">
                    <div><span class="details-label">Clients:</span></div>
                    <div v-for="client in props.item.clients" :key="client">
                      {{ client }}
                    </div>
                    <div class="mt-1" v-if="props.item.ownership">
                      <span class="details-label">Joint Type:</span>
                      {{ props.item.ownership }}
                    </div>
                  </td>
                  <td width="15%">
                    <div v-if="!editing">
                      <a class="action" @click="deleteAccount(props.index)">
                        <v-icon small :color="textColor"> fas fa-trash </v-icon>
                      </a>
                      <a class="action" @click="editAccount(props.index)">
                        <v-icon small :color="textColor"> fas fa-pencil</v-icon>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr v-else :key="props.item.label">
                  <td>
                    <ul class="flex-content">
                      <li class="flex-item">
                        <span class="details-label">Account Type:</span>
                        {{ props.item.type }}
                      </li>
                      <li class="flex-item mt-1">
                        <span class="details-label">Nickname:</span>
                        {{ props.item.label }}
                      </li>
                      <li class="flex-item details-label mt-1">Clients:</li>
                      <li
                        class="flex-item"
                        v-for="client in props.item.clients"
                        :key="client"
                      >
                        {{ client }}
                      </li>
                      <li v-if="props.item.ownership" class="flex-item mt-1">
                        <span class="details-label">Joint Type:</span>
                        {{ props.item.ownership }}
                      </li>
                      <li class="flex-item mt-1 actions-list-item">
                        <div v-if="!editing">
                          <a class="action" @click="deleteAccount(props.index)">
                            <v-icon small :color="textColor">
                              fas fa-trash
                            </v-icon>
                          </a>

                          <a class="action" @click="editAccount(props.index)">
                            <v-icon small :color="textColor">
                              fas fa-pencil
                            </v-icon>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </template>
              <div class="no-data" slot="no-data" :value="true">
                No account have been added yet. Choose an account type above and
                fill out the details to add one.
              </div>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row
          v-if="accountsErrors"
          class="step-form-container mobile justify-center align-center"
        >
          <v-col class="error-area">
            {{ accountsErrors }}
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>
<script>
import BottomNav from '@/components/common/BottomNav';
import { requiredIf } from 'vuelidate/lib/validators';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import {
  MENUS,
  MAX_ACCOUNTS_PER_TYPE,
  JOINT_NAME_BROKERAGE_TYPES,
} from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

function duplicateNickname(value) {
  if (!this.accounts) return true;

  let exists = false;
  this.accounts.forEach((a, idx) => {
    if (
      a.label === value &&
      (!this.editing || (this.editing && idx !== this.editIndex))
    )
      exists = true;
  });

  return !exists;
}

const selectionPerClient = (value, vm) => {
  let name1 = vm.clientOneName;
  let name2 = vm.clientTwoName;
  let c1 = false;
  let c2 = vm.addedSecondClient ? false : true;
  vm.accounts.forEach((a) => {
    if (a.clients.includes(name1)) c1 = true;
    if (a.clients.includes(name2)) c2 = true;
  });

  return c1 && c2;
};

export default {
  name: 'AccountSelection',

  mixins: [AdvisorClientMixin],
  components: { BottomNav },

  data: () => ({
    accounts: [],
    editIndex: null,
    isMobile: false,
    editing: false,
    addGroup: {
      accountType: null,
      nickname: null,
      accountClients: [],
      jnbType: null,
    },
  }),

  validations() {
    return {
      addGroup: {
        accountType: {
          required: requiredIf(() => !this.missingClients),
        },
        accountClients: {
          required: requiredIf(
            () =>
              !this.missingClients &&
              this.addGroup.accountType !==
                this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
          ),
        },
        nickname: {
          required: requiredIf(() => !this.missingClients),
          duplicateNickname,
        },
        jnbType: {
          required: requiredIf(
            () =>
              this.addGroup.accountType ===
              this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
          ),
        },
      },

      accounts: {
        required: requiredIf(() => !this.missingClients),
        selectionPerClient,
      },

      validationGroup: ['addGroup', 'accounts'],
    };
  },

  computed: {
    /* Dynamic errors */
    accountTypeErrors() {
      const errors = [];
      if (!this.$v.addGroup.accountType.$dirty) return errors;
      !this.$v.addGroup.accountType.required &&
        errors.push('An Account Type is required.');
      return errors;
    },
    nicknameErrors() {
      const errors = [];
      if (!this.$v.addGroup.nickname.$dirty) return errors;
      !this.$v.addGroup.nickname.required &&
        errors.push('An account nickname is required.');
      !this.$v.addGroup.nickname.duplicateNickname &&
        errors.push('Nickname must be unique across all accounts');
      return errors;
    },
    accountClientErrors() {
      const errors = [];
      if (!this.$v.addGroup.accountClients.$dirty) return errors;
      !this.$v.addGroup.accountClients.required &&
        errors.push('At least one client must be selected.');
      return errors;
    },
    jnbTypeErrors() {
      const errors = [];
      if (!this.$v.addGroup.jnbType.$dirty) return errors;
      !this.$v.addGroup.jnbType.required &&
        errors.push('At value must be selected.');
      return errors;
    },
    accountsErrors() {
      let errors = '';
      if (!this.$v.accounts.$dirty) return errors;
      if (!this.$v.accounts.selectionPerClient)
        errors = 'Each client must have at least one selected account type';
      return errors;
    },

    missingFirstClient() {
      return this.clientOneName.length === 0;
    },
    missingSecondClient() {
      return this.addedSecondClient && !this.isClientTwoNameComplete;
    },
    missingClients() {
      return this.missingFirstClient || this.missingSecondClient;
    },
    missingClientsText() {
      if (this.missingFirstClient && this.missingSecondClient) {
        return 'You must complete the first and second client name before choosing accounts.';
      } else if (this.missingFirstClient) {
        return 'You must complete the first client name before choosing accounts.';
      } else if (this.missingSecondClient) {
        return 'You must complete the second client name before choosing accounts.';
      }

      return '';
    },

    clientNames() {
      return this.isClientTwoNameComplete
        ? `${this.clientOneName}, ${this.clientTwoName}`
        : this.clientOneName;
    },

    clients() {
      const l = [this.clientOneName];
      if (this.isClientTwoNameComplete) l.push(this.clientTwoName);
      return l;
    },

    /* Table Header Definitions */
    headers() {
      const cols = [
        {
          text: 'Account Type',
          value: 'type',
        },
        {
          text: 'Nickname',
          value: 'label',
        },
        {
          text: 'Details',
          value: 'clients',
        },
        {
          text: '',
          sortable: false,
          align: 'end',
        },
      ];

      return cols;
    },
  },

  methods: {
    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Add/Save an account */
    saveAccount(editIdx = null) {
      this.$v.addGroup.$touch();
      if (this.$v.addGroup.$invalid) return;

      // Generate unique GUID
      const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16),
        );
      };

      // Check for exceeding max accounts per type
      // Only need this check if adding an account
      if (editIdx === null) {
        const accountTypeArr = this.accounts.filter(
          (a) => a.type === this.addGroup.accountType,
        );
        if (accountTypeArr && accountTypeArr.length >= MAX_ACCOUNTS_PER_TYPE) {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message:
              'Max ' + MAX_ACCOUNTS_PER_TYPE + ' accounts per account type',
            okTitle: 'Ok',
          });
          return;
        }
      }

      if (
        this.addGroup.accountClients.length > 1 &&
        this.addGroup.accountType !== this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE
      ) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message:
            'Nickname cannot be the same for multiple clients unless it is a Joint-Name Brokerage account.',
          okTitle: 'Ok',
        });
        return;
      }

      // Get account entry
      const account =
        editIdx !== null
          ? this.accounts[editIdx]
          : {
              type: this.addGroup.accountType,

              // When adding new account create a fake (temporary) accountId
              // so that we can uniquely identify this account when preserving
              // un-saved features/rates on edit.
              // The accountId will get replaced when saved on the backend.
              accountId: uuidv4(),
            };

      account.label = this.addGroup.nickname;
      account.clients = this.addGroup.accountClients;
      if (account.type === this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
        account.ownership = this.addGroup.jnbType;
        account.clients = [this.clientOneName, this.clientTwoName];
      }

      if (editIdx === null) this.accounts.push(account);
      this.clearAccount();
    },

    /* Delete an account */
    deleteAccount(idx) {
      let msg = 'Are you sure you want to delete this account?';
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: msg,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.accounts.splice(idx, 1);
        },
      });
    },

    /* Load account for editing */
    editAccount(idx) {
      this.editing = true;
      this.editIndex = idx;
      const account = this.accounts[idx];
      this.addGroup.accountType = account.type;
      this.addGroup.nickname = account.label;
      this.addGroup.accountClients = account.clients;
      this.addGroup.jnbType = account.ownership;
    },

    /* Clear in-progress account edit */
    clearAccount() {
      // Reset Add Account fields
      this.editing = false;
      this.editIndex = null;
      this.addGroup.accountType = null;
      this.addGroup.nickname = null;
      this.addGroup.accountClients = !this.hasSecondClient
        ? [this.clientOneName]
        : [];
      this.addGroup.jnbType = null;
      this.$v.$reset();
    },

    /* Initialize the account selections for the view */
    initializeAccounts() {
      if (!this.hasSecondClient)
        this.addGroup.accountClients = [this.clientOneName];
      this.accounts = this.getAccountSelections();
    },

    /* Get account selections for a specific client */
    getClientAccounts(client) {
      const clientAccounts = [];
      if (this.missingSecondClient) {
        return clientAccounts;
      }

      const name = `${client.firstName} ${client.lastName}`;

      // Get selected Accounts for a client
      this.accounts.forEach((a) => {
        if (a.clients.includes(name))
          clientAccounts.push({
            type: a.type,
            label: a.label,
            ownership: a.ownership,
            accountId: a.accountId,
          });
      });

      return clientAccounts;
    },

    /* Get original client accounts */
    getOriginalClientAccounts(client) {
      const clientAccounts = [];
      if (!client.accounts) return clientAccounts;

      client.accounts.forEach((a) => {
        clientAccounts.push({
          type: a.type,
          label: a.label,
          ownership: a.ownership,
        });
      });
      return clientAccounts;
    },

    mapClientAccountsData(client, newaccounts) {
      newaccounts.forEach((na) => {
        // Possibly use accountId to determine if an existing account
        const existingAccount =
          client.accounts &&
          client.accounts.find((ca) => ca.accountId === na.accountId);

        // Copy existing account features to the new account
        if (existingAccount) {
          na.accountId = existingAccount.accountId;
          na.rate = existingAccount.rate;
          na.moveMoney = existingAccount.moveMoney;
          na.acat = existingAccount.acat;
          na.iraDistribution = existingAccount.iraDistribution;
          na.masterAccountNumber = existingAccount.masterAccountNumber;
        }
      });

      client.accounts = newaccounts;
      console.log(client)
    },

    /* Map the account selections to the working client */
    mapClientAccounts() {
      // NOT SURE WHAT TO DO HERE
      // IF ACCOUNT SELECTIONS CHANGE - DO WE WIPE THE RATE/FEATURES/MASTER ACCOUNT NUMBER?
      // WE NEED SOME WAY TO SYNC EXISTING ACCOUNTS WITH WHAT IS MODIFIED ON THIS PAGE
      console.log(this.clientOne)
      console.log(this.clientTwo)
      const clientOneAccounts = this.getClientAccounts(this.clientOne);
      const clientTwoAccounts = this.getClientAccounts(this.clientTwo);
      const origClientOneAccounts = this.getOriginalClientAccounts(
        this.clientOne,
      );
      const origClientTwoAccounts = this.getOriginalClientAccounts(
        this.clientTwo,
      );

      // Unset features visited and reset advisory rate type if accounts have changed
      // This will force the advisor to revisit those pages to remake their selections
      // THIS LOGIC IS LIKELY TO CHANGE
      if (
        JSON.stringify(clientOneAccounts) !==
        JSON.stringify(origClientOneAccounts)
      ) {
        // UNUSED
        //this.currentClient.featuresVisited = false;
        //this.currentClient.rateType = null;

        //this.clientOne.accounts = clientOneAccounts;
        this.mapClientAccountsData(this.clientOne, clientOneAccounts);
      }
      if (
        this.hasSecondClient &&
        JSON.stringify(clientTwoAccounts) !==
          JSON.stringify(origClientTwoAccounts)
      ) {
        // UNUSED
        //this.currentClient.featuresVisited = false;
        //this.currentClient.rateType = null;

        //this.clientTwo.accounts = clientTwoAccounts;
        this.mapClientAccountsData(this.clientTwo, clientTwoAccounts);
      }

      //this.clientOne.accounts = clientOneAccounts;
      //if (this.hasSecondClient) this.clientTwo.accounts = clientTwoAccounts;
    },

    /* Map form data to working client */
    mapData() {
      this.mapClientAccounts();
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.accounts.$touch();
      if (this.$v.accounts.$invalid) return;

      this.mapData();
      const to = route ? route.name : MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // Determine previous page based on what makes sense
      //  If the first client name is not populated
      //  Go to the first client name page (first page in the first client flow)
      //
      //  Otherwise if a second client has been chosen and the name has been filled out
      //  Go to the second client phone page (last page in second client flow).
      //  Otherwise, go to the second client name page (first page in the second client flow).
      //
      //  Otherwise go to the Add Second Client question page
      let route = MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id;
      if (this.missingFirstClient) {
        route = MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id;
      } else if (this.addedSecondClient) {
        if (this.missingSecondClient) {
          route = MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id;
        } else {
          route = MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id;
        }
      }
      this.goPrev(route);

      /*
        // Non-intelligent nav
        // If added second client 
        //  Go to the second client phone page (last page in second client flow).
        //  Otherwise go to the Add Second Client question page
        this.goPrev(this.addedSecondClient ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id : MENUS.ADVISOR.CLIENT.CLIENT_ADD_SECOND.id);
      */
    },
  },

  created() {
    // Initialize static joint account types
    this.jnbTypes = [];
    Object.keys(JOINT_NAME_BROKERAGE_TYPES).map((key) => {
      this.jnbTypes.push(JOINT_NAME_BROKERAGE_TYPES[key]);
    });

    this.accountTypes = [];
    Object.keys(this.ACCOUNT_TYPES).map((key) => {
      // Filter JOINT if single client exists
      const val = this.ACCOUNT_TYPES[key];
      if (
        val !== this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE ||
        (val === this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE &&
          this.hasSecondClient)
      ) {
        this.accountTypes.push(val);
      }
    });

    this.initializeAccounts();
    this.$v.$reset();
  },
};
</script>
<style lang="scss" scoped>
.add-btn {
  width: 180px;
  margin-top: 30px;
  display: inline-block;
  margin-right: 8px;
}

.save-btn {
  width: 190px;
  margin-top: 30px;
  display: inline-block;
  margin-right: 8px;
}

.expander {
  padding-bottom: 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--secondary-color);

  &.no-bottom {
    border-bottom: none;
  }
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
}

.checkbox-container {
  overflow: hidden;
}

.close {
  float: right;
  padding: 0;
}

.full {
  width: 100%;
  max-width: 100%;
  margin-bottom: 4px;
}

.account-table {
  td {
    font-size: 14px !important;
  }

  div {
    line-height: normal;
  }

  .details-label {
    padding-right: 4px;
    font-weight: 600;
  }

  .action {
    padding: 8px 12px;
  }

  .flex-content {
    padding: 0;
  }

  .flex-item {
    line-height: normal;
  }

  .actions-list-item {
    border-top: 1px solid var(--secondary-color);
    padding-top: 6px;
    margin-top: 12px !important;
  }
}
</style>
